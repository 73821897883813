import React from 'react'
import { colorMapping } from '../../utils/colorMappings'

interface CustomInputFieldProps {
    value: string | number
    onChange?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
    placeholder?: string
    disabled?: boolean
    type?: string
    label?: string
    errorMessage?: string
    className?: string
    backgroundColor?: string
    multiline?: boolean
    icon?: React.ReactNode
    onHover?: (isHovered: boolean) => void
    hideBorder?: boolean
    onKeyDown?: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
}

const CustomInputField: React.FC<CustomInputFieldProps> = ({
    value,
    onChange,
    placeholder,
    disabled = false,
    type = 'text',
    label,
    errorMessage,
    className = '',
    backgroundColor = 'lightGray',
    multiline = false,
    icon,
    onHover,
    hideBorder = false,
    onKeyDown,
}) => {
    const lightColor = colorMapping[backgroundColor]?.light
    const darkColor = colorMapping[backgroundColor]?.dark

    return (
        <div className={`flex flex-col ${className}`}>
            {label && (
                <label className="mb-1.5 text-base text-blue flex items-center">
                    {label}
                    {icon && (
                        <span
                            className="ml-2 cursor-pointer"
                            onMouseEnter={() => onHover?.(true)}
                            onMouseLeave={() => onHover?.(false)}
                        >
                            {icon}
                        </span>
                    )}
                </label>
            )}
            {multiline ? (
                <textarea
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={`${hideBorder ? '' : 'border'} shadow shadow-blue bg-${lightColor} dark:bg-${darkColor} rounded-md px-3 py-1.5 outline-none focus:ring-2 focus:ring-blue disabled:cursor-not-allowed text-veryDarkGray dark:text-veryLightGray ${
                        // Added text color classes
                        errorMessage ? 'border-red' : 'border-darkGray'
                    }`}
                    rows={4}
                />
            ) : (
                <input
                    type={type}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={`${hideBorder ? '' : 'border focus:ring-2 focus:ring-blue'} shadow shadow-blue bg-${lightColor} dark:bg-${darkColor} rounded-md px-3 py-1.5 outline-none disabled:cursor-not-allowed text-veryDarkGray dark:text-veryLightGray ${
                        // Added text color classes
                        errorMessage ? 'border-red' : 'border-darkGray'
                    }`}
                    onKeyDown={onKeyDown}
                />
            )}
            {errorMessage && (
                <span className="text-red text-sm mt-1">{errorMessage}</span>
            )}
        </div>
    )
}

export default CustomInputField
