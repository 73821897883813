class LocalStorageUtil {
    static keys = {
        TOKEN: 'token',
        REFRESH_TOKEN: 'refreshToken',
        EMAIL: 'email',
        DARK_MODE: 'darkMode',
    }

    // Save the token
    static setToken(token: string) {
        localStorage.setItem(this.keys.TOKEN, token)
    }

    // Get the token
    static getToken() {
        return localStorage.getItem(this.keys.TOKEN)
    }

    // Remove the token
    static removeToken() {
        localStorage.removeItem(this.keys.TOKEN)
    }

    // Save the refresh token
    static setRefreshToken(refreshToken: string) {
        localStorage.setItem(this.keys.REFRESH_TOKEN, refreshToken)
    }

    // Get the refresh token
    static getRefreshToken() {
        return localStorage.getItem(this.keys.REFRESH_TOKEN)
    }

    // Remove the refresh token
    static removeRefreshToken() {
        localStorage.removeItem(this.keys.REFRESH_TOKEN)
    }

    // Save the email
    static setEmail(email: string) {
        localStorage.setItem(this.keys.EMAIL, email)
    }

    // Get the email
    static getEmail() {
        return localStorage.getItem(this.keys.EMAIL)
    }

    // Remove the email
    static removeEmail() {
        localStorage.removeItem(this.keys.EMAIL)
    }

    // Save the dark mode preference
    static setDarkMode(darkMode: boolean) {
        localStorage.setItem(this.keys.DARK_MODE, darkMode.toString())
    }

    // Get the dark mode preference
    static isDarkMode() {
        return localStorage.getItem(this.keys.DARK_MODE) === 'true'
    }

    static isUserLoggedIn() {
        return this.getToken() !== null
    }

    // Clear all data (useful for logout)
    static clearAll() {
        localStorage.removeItem(this.keys.TOKEN)
        localStorage.removeItem(this.keys.REFRESH_TOKEN)
        localStorage.removeItem(this.keys.EMAIL)
    }
}

export default LocalStorageUtil
