const LinkedInRedirect = () => {
    return (
        <div className="flex items-center h-[100px] justify-center text-center text-veryDarkGray dark:text-veryLightGray">
            <div className="flex items-center h-[100px] justify-center text-center text-veryDarkGray dark:text-veryLightGray">
                <span>Please create a profile</span>
            </div>
        </div>
    )
}

export default LinkedInRedirect
