import React, { useState } from 'react'

const ToggleButtonGroup = ({
    options,
    onToggle,
    primaryColor = 'blue',
    preSelected,
}: {
    options: string[]
    onToggle?: (selected: string) => void
    primaryColor?: string
    preSelected?: string
}) => {
    const [selected, setSelected] = useState<string>(() => {
        return preSelected || options[0]
    })

    const handleClick = (option: string) => {
        if (option !== selected) {
            setSelected(option)
            if (onToggle) onToggle(option)
        }
    }

    return (
        <div className="flex">
            {options.map((option, index) => (
                <button
                    key={option}
                    onClick={() => handleClick(option)}
                    className={`px-5 py-1 text-sm ${
                        selected === option
                            ? `text-${primaryColor} font-bold border-${primaryColor} border-2 rounded-l-md bg-lightGray dark:bg-darkBlue`
                            : 'text-gray dark:text-darkGray bg-lightGray dark:bg-darkBlue dark:border-darkGray border'
                    } ${index === options.length - 1 ? 'rounded-r-md' : ''} ${
                        index === 0 ? 'rounded-l-md' : ''
                    }`}
                >
                    {option}
                </button>
            ))}
        </div>
    )
}

export default ToggleButtonGroup
