import Card from '../../components/atoms/Card'
import { ChangeEvent, useState } from 'react'
import CustomInputField from '../../components/atoms/CustomInputField'
import DynamicListManager from '../../components/molecules/DynamicListManager'
import { CustomButton } from '../../components/buttons/CustomButton'
import { ProjectId, ProjectOutput } from '../../api/openapi'
import { useNavigate } from 'react-router-dom'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import {
    closeProject,
    createProject,
    updateProject,
} from '../../middleware/projectService'
import CustomCheckbox from '../../components/atoms/CustomCheckbox'
import { ApiFunction } from '../../types/apiFunction'
import { toast } from 'react-toastify'
import Modal from '../../components/atoms/Modal'
import TextButton from '../../components/buttons/TextButton'
import { useData } from '../../context/WizardContext'
import LocalStorageUtil from '../../utils/LocalStorageUtil'

export const ProjectSettingsCard = ({
    project,
    onCancelClose,
    onSaveClose,
}: {
    project: ProjectOutput | null
    onCancelClose: () => void
    onSaveClose: () => void
}) => {
    const [title, setTitle] = useState(project?.title || '')
    const [description, setDescription] = useState(project?.description || '')
    // const [institution, setInstitution] = useState(project?.owner || '')
    const [tags, setTags] = useState<string[]>(project?.tags || [])
    const [collaborators, setCollaborators] = useState<string[]>(
        project?.collaborators || []
    )
    const [closeProjectModalOpen, setCloseProjectModalOpen] =
        useState<boolean>(false)
    const [projectTitleInput, setProjectTitleInput] = useState<string>('')
    const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')
    const [descriptionErrorMessage, setDescriptionErrorMessage] =
        useState<string>('')

    const { fetchData: backendCall, loading } = useFetchOnDemand(
        async (apiFun) => {
            if (apiFun === ApiFunction.UPDATE) {
                return updateProject({
                    id: project!.id,
                    title,
                    description,
                    tags,
                    collaborators,
                })
            } else if (apiFun === ApiFunction.CREATE) {
                return createProject({
                    title,
                    description,
                    tags,
                    collaborators,
                })
            } else {
                return closeProject(project!.id)
            }
        },
        (data) => {
            return data
        }
    )

    const navigate = useNavigate()

    const handleCreateProject = async () => {
        backendCall(ApiFunction.CREATE).then((result) => {
            navigate(
                '/wizard/' +
                    (result as ProjectId).projectId +
                    '/literature-search'
            )
        })
    }

    const handleUpdateProject = async () => {
        //if title is not valid show error message
        if (
            titleErrorMessage.length > 0 ||
            descriptionErrorMessage.length > 0
        ) {
            return
        }

        backendCall(ApiFunction.UPDATE).then(() => {
            toast.success('Project updated successfully')
            onSaveClose()
        })
    }

    const handleDeleteProject = async () => {
        if (!project) return
        if (projectTitleInput !== project.title) {
            toast.error('Project title does not match')
            return
        }
        backendCall(ApiFunction.DELETE).then(() => {
            toast.success('Project closed successfully')
            onSaveClose()
            navigate('/')
        })
    }

    const handleClose = () => {
        onCancelClose()
    }

    const handleTagsChange = (updatedTags: string[]) => {
        setTags(updatedTags)
    }

    const handleCollaboratorsChange = (updatedCollaborators: string[]) => {
        setCollaborators(updatedCollaborators)
    }

    const handleTitleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        //if less then 3 characters or more then 50 characters show error message
        if (e.target.value.length < 3) {
            setTitleErrorMessage('Title must be at least 3 characters long')
        } else if (e.target.value.length > 200) {
            setTitleErrorMessage('Title must be at most 200 characters long')
        } else {
            setTitleErrorMessage('')
        }

        setTitle(e.target.value)
    }

    const handleDescriptionChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        //if less then 3 characters or more then 50 characters show error message
        if (e.target.value.length < 3) {
            setDescriptionErrorMessage(
                'Description must be at least 3 characters long'
            )
        } else if (e.target.value.length > 1000) {
            setDescriptionErrorMessage(
                'Description must be at most 10000 characters long'
            )
        } else {
            setDescriptionErrorMessage('')
        }

        setDescription(e.target.value)
    }

    return (
        <Card>
            <div
                className={`flex flex-col gap-4 px-5 pt-4 pb-5 min-w-[600px] max-w-[600px] text-veryDarkGray`}
            >
                <div className={`text-2xl font-bold text-blue text-left`}>
                    Project Settings
                </div>

                <div className={`flex flex-col gap-4 pb-4`}>
                    <CustomInputField
                        value={title}
                        onChange={handleTitleChange}
                        label="Title"
                        placeholder={'Enter project title'}
                        errorMessage={titleErrorMessage}
                    />

                    <CustomInputField
                        value={description}
                        onChange={handleDescriptionChange}
                        label="Description"
                        placeholder={'Enter project description'}
                        errorMessage={descriptionErrorMessage}
                        multiline
                    />

                    {/*<CustomInputField*/}
                    {/*    value={institution}*/}
                    {/*    onChange={(e) => setInstitution(e.target.value)}*/}
                    {/*    label="Institution"*/}
                    {/*    placeholder={'Enter institution name'}*/}
                    {/*/>*/}

                    <DynamicListManager
                        initialItems={tags}
                        placeholder={'Enter tags'}
                        onListChange={handleTagsChange}
                        backgroundColor={'lightGray'}
                    />

                    <DynamicListManager
                        initialItems={collaborators}
                        placeholder={'Enter collaborators'}
                        onListChange={handleCollaboratorsChange}
                        backgroundColor={'lightGray'}
                        emailValidation={true}
                        maxItems={5}
                    />
                    <CustomCheckbox label="Notify me via E-mail when a long running process finishes." />
                    {project &&
                        project.owner.toLowerCase() ===
                            LocalStorageUtil.getEmail()!.toLowerCase() &&
                        project.status === 'ACTIVE' && (
                            // <div className="flex flex-row gap-2 font-bold text-red w-fit">
                            //     <div
                            //         className={
                            //             'bg-red text-white rounded-md px-2 py-0.5 transition-transform transform hover:scale-110 hover:cursor-pointer'
                            //         }
                            //         onClick={() => setCloseProjectModalOpen(true)}
                            //     >
                            //         Eliminate
                            //     </div>
                            //     <div>this project</div>
                            // </div>
                            <TextButton
                                onClick={() => setCloseProjectModalOpen(true)}
                                buttonText="Eliminate"
                                additionalText="this project"
                            />
                        )}
                </div>
                {project && (
                    <div className={`flex flex-row gap-3 self-end`}>
                        <CustomButton
                            backgroundColor={'lightGray'}
                            textColor={'blue'}
                            text={'Close'}
                            onClick={handleClose}
                        />
                        {project && project.status === 'ACTIVE' && (
                            <CustomButton
                                text={'Save'}
                                onClick={handleUpdateProject}
                                loading={loading && !closeProjectModalOpen}
                            />
                        )}
                    </div>
                )}
                {!project && (
                    <div className={`flex flex-row gap-3 self-end`}>
                        <CustomButton
                            backgroundColor={'lightGray'}
                            textColor={'blue'}
                            text={'Cancel'}
                            onClick={handleClose}
                        />
                        <CustomButton
                            text={'Start'}
                            onClick={handleCreateProject}
                            loading={loading && !closeProjectModalOpen}
                        />
                    </div>
                )}
            </div>
            <Modal
                isOpen={closeProjectModalOpen}
                onClose={() => setCloseProjectModalOpen(false)}
            >
                <Card>
                    <div className={'flex flex-col gap-4'}>
                        <div
                            className={
                                'md:max-w-[500px] lg:max-w-[700px] text-veryDarkGray dark:text-veryLightGray'
                            }
                        >
                            To{' '}
                            <span className={'text-red font-bold'}>
                                eliminate
                            </span>{' '}
                            the project, type the title of the project "
                            {project?.title}" below.
                        </div>
                        <CustomInputField
                            value={projectTitleInput}
                            onChange={(e) =>
                                setProjectTitleInput(e.target.value)
                            }
                            placeholder={'Enter project title'}
                        />
                        <div className={'flex flex-row gap-4 justify-center'}>
                            <CustomButton
                                backgroundColor={'lightGray'}
                                textColor={'blue'}
                                text={'Cancel'}
                                onClick={() => {
                                    setProjectTitleInput('')
                                    setCloseProjectModalOpen(false)
                                }}
                            />
                            <CustomButton
                                textColor={'white'}
                                backgroundColor={'red'}
                                text={'Eliminate Project'}
                                onClick={handleDeleteProject}
                                loading={loading && closeProjectModalOpen}
                            />
                        </div>
                    </div>
                </Card>
            </Modal>
        </Card>
    )
}
