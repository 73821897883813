import Card from '../../components/atoms/Card'
import React, { useEffect, useState } from 'react'
import { CustomButton } from '../../components/buttons/CustomButton'
import ToggleButtonGroup from '../../components/atoms/ToggleButtonGroup'
import { ArticlesListCard } from '../../components/molecules/ArticlesListCard'
import { JournalsListCard } from '../../components/molecules/JournalsListCard'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetch } from '../../hooks/useFetch'
import { getIndividualIdea } from '../../middleware/ideasService'
import { StageEnum } from '../../api/openapi'
import CommentSection from '../../features/comment-section/CommentSection'
import { FunctionButtonsColumn } from '../../components/molecules/FunctionButtonsColumn'
import { IoEye } from 'react-icons/io5'
import { ClinicalTrialsTable } from '../../features/clinical-trials-table/ClinicalTrialsTable'
import { TableViewButton } from '../../components/buttons/TableViewButton'
import { useData } from '../../context/WizardContext'

export const IdeaPreview = () => {
    const { projectId } = useParams<{ projectId: string }>()
    const { ideasSetId } = useParams<{ ideasSetId: string }>()
    const { ideaId } = useParams<{ ideaId: string }>()
    const navigate = useNavigate()
    const { isOwner } = useData()

    const [publicationTypeToggle, setPublicationTypeToggle] = useState(false)
    const [articlesView, setArticlesView] = useState<boolean>(false)

    const {
        data: idea,
        error,
        loading,
    } = useFetch(() => getIndividualIdea(projectId!, ideaId!))

    const handleNext = () => {
        navigate(
            '/wizard/' +
                projectId +
                '/protocol-generation/' +
                ideasSetId +
                '/' +
                ideaId
        )
    }

    const handleSearchToggle = (selected: string) => {
        setPublicationTypeToggle(!publicationTypeToggle)
    }

    const handleArticlesView = () => {
        setArticlesView(!articlesView)
    }

    return (
        <div className={'flex flex-col items-center gap-8 wizard-width'}>
            {idea && (
                <div className={'flex flex-col w-full items-center gap-8'}>
                    <div
                        className={`flex flex-row w-full items-start gap-8 ${articlesView ? 'w-full lg:max-w-[1000px] xl:max-w-[1100px] 2xl:w-[1500px]' : 'wizard-width'}  relative`}
                    >
                        {!articlesView && (
                            <Card>
                                <div className="flex flex-col w-full items-left gap-4 rounded-md">
                                    <div className="flex-1">
                                        <TableViewButton
                                            backgroundColorClass={
                                                'white dark:bg-darkBlue'
                                            }
                                            text={'Relevant clinical trials'}
                                            onClick={handleArticlesView}
                                            icon={
                                                <IoEye
                                                    className={`text-blue w-6 h-6`}
                                                />
                                            }
                                        />
                                    </div>
                                    <Card backgroundColor={'lightGray'}>
                                        <div className="flex flex-col font-bold text-base pb-1">
                                            <div
                                                className={`text-blue text-lg`}
                                            >
                                                Title selected:
                                            </div>
                                            <div
                                                className={`text-veryDarkGray dark:text-yellow text-lg`}
                                            >
                                                {idea.title}
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-0.5 font-bold">
                                            <div
                                                className={`text-base text-blue`}
                                            >
                                                Target outcome:
                                            </div>
                                            <div
                                                className={`text-sm text-veryDarkGray dark:text-veryLightGray`}
                                            >
                                                {idea.targetOutcome}
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-0.5 font-bold">
                                            <div
                                                className={`text-base text-blue`}
                                            >
                                                Last review found on topic:
                                            </div>
                                            <div
                                                className={`text-sm text-veryDarkGray dark:text-veryLightGray`}
                                            >
                                                {idea.lastReview?.title ?? '/'}
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-0.5 font-bold">
                                            <div
                                                className={`text-base text-blue`}
                                            >
                                                Last review’s publication date:
                                            </div>
                                            <div
                                                className={`text-sm text-veryDarkGray dark:text-veryLightGray`}
                                            >
                                                {idea.lastReview
                                                    ?.publicationDate ?? '/'}
                                            </div>
                                        </div>
                                    </Card>

                                    <div
                                        className={`flex flex-col items-start gap-4`}
                                    >
                                        <div className={`text-sm text-gray`}>
                                            We will generate the draft based on
                                            a subset of articles and reviews
                                            related to the selected article.
                                        </div>
                                        <div
                                            className={`flex flex-col gap-2 w-full`}
                                        >
                                            <div
                                                className={`flex flex-col items-start`}
                                            >
                                                <ToggleButtonGroup
                                                    options={[
                                                        `Articles (${idea.articles?.length})`,
                                                        `Reviews (${idea.reviews?.length})`,
                                                    ]}
                                                    onToggle={
                                                        handleSearchToggle
                                                    }
                                                />
                                            </div>
                                            {!publicationTypeToggle && (
                                                <ArticlesListCard
                                                    backgroundColor={
                                                        'lightGray'
                                                    }
                                                    articles={
                                                        idea.articles ?? []
                                                    }
                                                    disableSelect={true}
                                                />
                                            )}
                                            {publicationTypeToggle && (
                                                <ArticlesListCard
                                                    backgroundColor={
                                                        'lightGray'
                                                    }
                                                    articles={
                                                        idea.reviews ?? []
                                                    }
                                                    disableSelect={true}
                                                />
                                            )}
                                        </div>
                                        <JournalsListCard
                                            journals={
                                                idea.potentialJournals ?? []
                                            }
                                        />
                                    </div>
                                </div>
                            </Card>
                        )}
                        {articlesView && (
                            <ClinicalTrialsTable
                                trials={idea.clinicalTrials!}
                                onClose={() => setArticlesView(false)}
                            />
                        )}
                        <FunctionButtonsColumn
                            projectId={projectId!}
                            onShowHistoryClick={() => {}}
                        />
                    </div>
                    <div className={`flex self-end`}>
                        {isOwner && !articlesView && (
                            <CustomButton
                                text={'Generate Protocol!'}
                                onClick={handleNext}
                            />
                        )}
                    </div>
                </div>
            )}

            {loading && (
                <div className={'dark:text-veryLightGray'}>Loading...</div>
            )}
            {error && (
                <div className={'dark:text-veryLightGray'}>Error: {error}</div>
            )}

            <CommentSection projectId={projectId!} stage={StageEnum.Ideas} />
        </div>
    )
}
