import { FaMinus } from 'react-icons/fa'

export const MinusButton = ({
    onClick,
    iconSize = 6,
    disabled = false,
}: {
    onClick: () => void
    iconSize?: number
    disabled?: boolean
}) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`flex items-center justify-center rounded-full transition-transform transform hover:scale-110
            ${disabled ? 'bg-lightGray text-veryDarkGray cursor-not-allowed' : 'bg-yellow text-white'} w-${iconSize * 2} h-${iconSize * 2} p-1`}
        >
            <FaMinus className={`w-${iconSize} h-${iconSize}`} />
        </button>
    )
}
