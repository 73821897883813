import React, { createContext, useContext, useState, useEffect } from 'react'
import { ProjectOutput, UserHistoryV2 } from '../api/openapi'
import { useParams } from 'react-router-dom'
import { fetchProjectData } from '../middleware/projectService'
import LocalStorageUtil from '../utils/LocalStorageUtil'

// Create the context
const WizardContext = createContext<{
    historyData: UserHistoryV2 | null
    projectData: ProjectOutput | null
    loading: boolean
    error: any
    isOwner: boolean
    refreshData: () => void
}>({
    historyData: null,
    projectData: null,
    loading: true,
    error: null,
    isOwner: false,
    refreshData: () => {},
})

// Custom hook to use the WizardContext
export const useData = () => useContext(WizardContext)

// Provider component
export const WizardProvider = ({ children }: { children: React.ReactNode }) => {
    const { projectId } = useParams<{ projectId: string }>()
    const { searchId } = useParams<{ searchId: string }>()
    const { ideasSetId } = useParams<{ ideasSetId: string }>()
    const { ideaId } = useParams<{ ideaId: string }>()

    const [projectData, setProjectData] = useState<ProjectOutput | null>(null)
    const [historyData, setHistoryData] = useState<UserHistoryV2 | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [isOwner, setIsOwner] = useState(false)

    // Fetch data from the API
    const fetchData = () => {
        setLoading(true)
        fetchProjectData(projectId!).then((data) => {
            setIsOwner(
                data.information?.owner.toLowerCase() ===
                    LocalStorageUtil.getEmail()!.toLowerCase()
            )
            setProjectData(data.information!)
            setHistoryData(data.history!)
            setLoading(false)
        })
    }

    // Fetch data on component mount
    useEffect(() => {
        fetchData()
    }, [projectId, searchId, ideasSetId, ideaId])

    const refreshData = () => {
        fetchData()
    }

    if (loading) {
        return (
            <div className="flex flex-col items-center gap-6">
                <div
                    className={
                        'text-base text-darkGray dark:text-veryLightGray'
                    }
                >
                    Loading project data...
                </div>
            </div>
        )
    }

    return (
        <WizardContext.Provider
            value={{
                projectData,
                historyData,
                loading,
                error,
                isOwner,
                refreshData,
            }}
        >
            {children}
        </WizardContext.Provider>
    )
}
