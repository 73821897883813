import Card from '../../components/atoms/Card'
import { Title } from '../../components/atoms/Title'
import { CustomButton } from '../../components/buttons/CustomButton'
import Modal from '../../components/atoms/Modal'
import React from 'react'

interface InfoModalProps {
    title: string
    text: string
    isOpen: boolean
    closeAction: () => void
}

export const InfoModal = ({
    title,
    text,
    isOpen,
    closeAction,
}: InfoModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={() => closeAction()}>
            <Card>
                <div
                    className={
                        'flex flex-col gap-4 items-center md:max-w-[500px] lg:max-w-[700px] text-veryDarkGray dark:text-veryLightGray'
                    }
                >
                    <Title text={title} />
                    <div className={'text-center'}>
                        <p>{text}</p>
                    </div>
                    <div className={'flex flex-row gap-4'}>
                        <CustomButton
                            backgroundColor={'lightGray'}
                            textColor={'blue'}
                            text={'Close'}
                            onClick={() => closeAction()}
                        />
                    </div>
                </div>
            </Card>
        </Modal>
    )
}
