import { IoSearch } from 'react-icons/io5'

interface FunctionButtonProps {
    onClick?: () => void
    className?: string
    icon?: React.ReactNode
}

export const FunctionButton = ({
    onClick,
    className,
    icon = <IoSearch className="w-6 h-6" />,
}: FunctionButtonProps) => {
    return (
        <button
            onClick={onClick}
            className={`flex items-center justify-center w-8 h-8 rounded-md bg-white dark:bg-bgBlack text-blue border-2
            transition-transform transform hover:scale-110 shadow-blueCustomShadow
            ${className}`}
        >
            {icon}
        </button>
    )
}
