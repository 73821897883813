import React, { useState } from 'react'
import Card from '../atoms/Card'
import { Title } from '../atoms/Title'
import { ProjectCard } from './ProjectCard'
import ArrowButton from '../buttons/ArrowButton'
import { ProjectOutput } from '../../api/openapi'
import Modal from '../atoms/Modal'
import { ProjectSettingsCard } from '../../features/project-settings/ProjectSettingsCard'
import { useNavigate } from 'react-router-dom'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { IconButton } from '../buttons/IconButton'
import { FaPlus } from 'react-icons/fa'
import { getHistory } from '../../middleware/historyService'
import useWindowSize from '../../hooks/useWindowSize'

export const ProjectsViewCard = ({
    historyView = false,
    data,
    refreshData,
}: {
    historyView: boolean
    data: ProjectOutput[]
    refreshData: () => void
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedProject, setSelectedProject] =
        useState<ProjectOutput | null>(null) // for project settings
    const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
        null
    ) // for project wizard
    const [currentIndex, setCurrentIndex] = useState(0)

    const navigate = useNavigate()
    const { fetchData: getProjectHistoryCall, loading: loadingProjectHistory } =
        useFetchOnDemand((projectId: string) => getHistory(projectId))

    const { width } = useWindowSize()

    let projectsPerPage = 1 // Default for super small screens
    if (width >= 1536) {
        projectsPerPage = 3 // Large screens
    } else if (width >= 1024) {
        projectsPerPage = 2 // Medium screens
    }

    const displayedProjects = data
        ? data.slice(currentIndex, currentIndex + projectsPerPage)
        : []

    const openModal = () => setIsModalOpen(true)
    const closeModal = () => setIsModalOpen(false)

    const onAddProject = () => {
        setSelectedProject(null)
        openModal()
    }

    const onPrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - projectsPerPage)
        }
    }

    const onNext = () => {
        if (data && currentIndex < data.length - projectsPerPage) {
            setCurrentIndex(currentIndex + projectsPerPage)
        }
    }

    const onProjectSettingsClick = (index: number) => {
        const projectIndex = currentIndex + index
        setSelectedProject(data![projectIndex] || null)
        setIsModalOpen(true)
    }

    const onProjectClick = (project: ProjectOutput) => {
        setSelectedProjectId(project.id)
        getProjectHistoryCall(project.id).then((data) => {
            if (!data) return

            const latestHistory = data.items?.sort((a, b) =>
                a.timestamp.localeCompare(b.timestamp)
            )[0]

            if (!latestHistory) {
                navigate(`/wizard/${project.id}/literature-search`)
                return
            }
            if (latestHistory.ideaId && latestHistory.protocolId) {
                navigate(
                    `/wizard/${project.id}/protocol-generation/${latestHistory.ideasSetId}/${latestHistory.ideaId}/${latestHistory.protocolId}`
                )
                return
            }
            if (latestHistory.ideasSetId) {
                navigate(
                    `/wizard/${project.id}/ideas-generation/${latestHistory.searchId}/${latestHistory.ideasSetId}`
                )
                return
            }
            if (latestHistory.searchId) {
                navigate(
                    `/wizard/${project.id}/literature-search/${latestHistory.searchId}`
                )
                return
            }
            navigate(`/wizard/${project.id}/literature-search`)
        })
    }

    return (
        <div className="flex flex-col gap-4 project-view-width">
            <Card>
                <div className="flex flex-col gap-6 pb-2 px-2 items-center">
                    {!historyView && (
                        <div className="flex flex-row gap-2 items-center w-full">
                            <div className="flex flex-grow justify-end">
                                <Title text="Project view" />
                            </div>
                            <div className="flex flex-grow justify-end ml-auto">
                                <IconButton
                                    onClick={onAddProject}
                                    icon={<FaPlus className="w-6 h-6" />}
                                />
                            </div>
                        </div>
                    )}

                    <div className="flex flex-row gap-8 items-center">
                        <div
                            className={`${currentIndex > 0 ? 'block' : 'invisible'}`}
                        >
                            <ArrowButton
                                iconSize={6}
                                direction="left"
                                onClick={onPrevious}
                            />
                        </div>
                        <div
                            className={`grid gap-4 ${
                                width >= 1536
                                    ? 'grid-cols-3'
                                    : width >= 1024
                                      ? 'grid-cols-2'
                                      : 'grid-cols-1'
                            }`}
                        >
                            {displayedProjects.map((project, index) => (
                                <ProjectCard
                                    key={project.id}
                                    project={project}
                                    onSettingsClick={() =>
                                        onProjectSettingsClick(index)
                                    }
                                    onProjectClick={() =>
                                        onProjectClick(project)
                                    }
                                    isLoading={project.id === selectedProjectId}
                                />
                            ))}
                        </div>
                        <div
                            className={`${
                                currentIndex < data.length - projectsPerPage
                                    ? 'block'
                                    : 'invisible'
                            }`}
                        >
                            <ArrowButton
                                iconSize={6}
                                direction="right"
                                onClick={onNext}
                            />
                        </div>
                    </div>
                </div>
            </Card>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ProjectSettingsCard
                    project={selectedProject}
                    onCancelClose={closeModal}
                    onSaveClose={() => {
                        closeModal()
                        refreshData()
                    }}
                />
            </Modal>
        </div>
    )
}
