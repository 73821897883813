import Card from '../../components/atoms/Card'
import ToggleButtonGroup from '../../components/atoms/ToggleButtonGroup'
import CustomInputField from '../../components/atoms/CustomInputField'
import React, { useEffect, useState } from 'react'
import { CustomButton } from '../../components/buttons/CustomButton'
import { ArrowToggleButton } from '../../components/atoms/ArrowToggleButton'
import {
    generatePicoQuery,
    createSearch,
    previewSearchPubmed,
    getArticlesAndReviews,
} from '../../middleware/searchService'
import { useNavigate, useParams } from 'react-router-dom'
import LiteratureSearchHistorySection from '../../features/history-section/literature-search/LiteratureSearchHistorySection'
import CommentSection from '../../features/comment-section/CommentSection'
import { SearchHistoryItemShort, StageEnum } from '../../api/openapi'
import { FunctionButtonsColumn } from '../../components/molecules/FunctionButtonsColumn'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'

import { FiRefreshCcw } from 'react-icons/fi'
import { IconButton } from '../../components/buttons/IconButton'
import { IoSearch } from 'react-icons/io5'
import { useData } from '../../context/WizardContext'

export const LiteratureSearch = () => {
    const { projectId } = useParams<{ projectId: string }>()
    const { searchId } = useParams<{ searchId?: string }>()
    const { isOwner } = useData()
    const navigate = useNavigate()

    const [inputValueQuickSearch, setInputValueQuickSearch] = useState(
        'Telerehabilitation in patients with COPD and Covid.'
    )
    const [inputValueCustomSearchQuery, setInputValueCustomSearchQuery] =
        useState('(COVID-19 OR coronavirus OR SARS-CoV-2)')
    const [fromYear, setFromYear] = useState(2010)
    const [toYear, setToYear] = useState(2024)

    //state for advanced search
    const [advancedSearch, setAdvancedSearch] = useState(false)
    const [customSearchQuery, setCustomSearchQuery] = useState(true)
    const [showQueryPreview, setShowQueryPreview] = useState(false)
    const [showDateRange, setShowDateRange] = useState(false)

    //data model for PICO/PECO
    const [population, setPopulation] = useState('')
    const [intervention, setIntervention] = useState('')
    const [outcome, setOutcome] = useState('')
    const [others, setOthers] = useState('')

    //data model for results
    const [numberOfArticles, setNumberOfArticles] = useState(0)
    const [numberOfReviews, setNumberOfReviews] = useState(0)
    const [inputValueQueryResult, setInputValueQueryResult] = useState('')
    const [keyWords, setKeyWords] = useState<string[]>([])
    const [createSearchQuickSearchField, setCreateSearchQuickSearchField] =
        useState('')

    //api
    const {
        fetchData: getSearchCall,
        loading: loadingGetSearch,
        error: errorGetSearch,
    } = useFetchOnDemand(() => {
        return getArticlesAndReviews(projectId!, searchId!)
    })
    const { fetchData: previewSearch, loading: loadingPreviewSearch } =
        useFetchOnDemand(() => {
            return previewSearchPubmed(
                advancedSearch && customSearchQuery
                    ? inputValueCustomSearchQuery
                    : inputValueQuickSearch,
                fromYear,
                toYear
            )
        })
    const {
        fetchData: previewSearchFromResultInput,
        loading: loadingPreviewSearchFromResultInput,
    } = useFetchOnDemand(() => {
        return previewSearchPubmed(inputValueQueryResult, fromYear, toYear)
    })
    const {
        fetchData: generatePicoQueryCall,
        loading: loadingGeneratePicoQueryCall,
    } = useFetchOnDemand(() => {
        return generatePicoQuery(population, intervention, others, outcome)
    })
    const { fetchData: createSearchCall, loading: loadingCreateSearchCall } =
        useFetchOnDemand(() => {
            return createSearch(
                projectId!,
                inputValueQuickSearch === ''
                    ? createSearchQuickSearchField
                    : inputValueQuickSearch,
                inputValueQueryResult,
                fromYear,
                toYear,
                keyWords
            )
        })

    useEffect(() => {
        if (searchId) {
            getSearchCall().then((data) => {
                if (!data) return
                setInputValueQueryResult(data.processedQuery!)
                setKeyWords(data.keywords!)
                setNumberOfArticles(data.numArticlesRetrieved!)
                setNumberOfReviews(data.numReviewsRetrieved!)
                setInputValueQuickSearch(data.originalQuery || '')
                setFromYear(data.minYear!)
                setToYear(data.maxYear!)
                setShowDateRange(data.minYear !== 0 || data.maxYear !== 0)
                setShowQueryPreview(true)
            })
        }
    }, [searchId])

    const handleSearchToggle = (selected: string) => {
        setAdvancedSearch(selected === 'Advanced search')
        setCustomSearchQuery(true)
    }

    const handleQueryToggle = (selected: string) => {
        setCustomSearchQuery(selected === 'Custom search query')
    }

    const handleSearch = () => {
        previewSearch().then((result) => {
            if (!result) return
            setNumberOfArticles(result.numArticles)
            setNumberOfReviews(result.numReviews)
            setInputValueQueryResult(result.query)
            setKeyWords(result.keywords)
            setShowQueryPreview(true)
        })
    }

    const handleRefresh = () => {
        setInputValueQuickSearch('')
        setInputValueCustomSearchQuery('')
        setPopulation('')
        setIntervention('')
        setOutcome('')
        setOthers('')

        previewSearchFromResultInput().then((result) => {
            if (!result) return
            setCreateSearchQuickSearchField(
                'Custom: ' + result.keywords.join('')
            )
            setNumberOfArticles(result.numArticles)
            setNumberOfReviews(result.numReviews)
            setInputValueQueryResult(result.query)
            setKeyWords(result.keywords)
            setShowQueryPreview(true)
        })
    }

    const handleGenerateQuery = () => {
        generatePicoQueryCall().then((result) => {
            if (!result) return
            setCreateSearchQuickSearchField('PICO: ' + result.keywords)
            setInputValueQueryResult(result.searchQuery)
            setKeyWords(result.keywords)
            setShowQueryPreview(true)
        })
    }

    const handleExtractResults = () => {
        createSearchCall().then((result) => {
            if (!result) return
            navigate(
                '/wizard/' +
                    projectId +
                    '/results-extraction/' +
                    result.searchId
            )
        })
    }

    const handleYearChange = (
        type: 'from' | 'to',
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        //check if the input is a number
        if (isNaN(Number(e.target.value)) || e.target.value.length !== 4) {
            // console.log('Invalid year')
            // if (type === 'from') {
            //     setFromYearErrorMessage('Invalid input')
            // } else {
            //     setToYearErrorMessage('Invalid input')
            // }
            return
        } else {
            console.log('Valid year')
            if (type === 'from') {
                setFromYear(Number(e.target.value))
            } else {
                setToYear(Number(e.target.value))
            }
        }
    }

    return (
        <div className={'flex flex-col items-center gap-8 w-full'}>
            {loadingGetSearch && (
                <div className={'text-darkGray dark:text-veryLightGray'}>
                    Loading...
                </div>
            )}
            {errorGetSearch && (
                <div className={'text-darkGray dark:text-veryLightGray'}>
                    Error: {errorGetSearch}
                </div>
            )}
            {!loadingGetSearch && !errorGetSearch && (
                <div
                    className={`flex flex-col items-center ${isOwner ? 'gap-8' : ''} wizard-width`}
                >
                    <div
                        className={
                            'flex flex-row items-start gap-8 w-full relative'
                        }
                    >
                        {!isOwner && !searchId && (
                            <Card>
                                <div className="flex flex-col items-center gap-6">
                                    <div
                                        className={
                                            'text-base text-darkGray dark:text-veryLightGray'
                                        }
                                    >
                                        The owner of this project hasn't
                                        generated any ideas yet.
                                    </div>
                                </div>
                            </Card>
                        )}
                        {isOwner && (
                            <Card>
                                <div className="flex flex-col items-start gap-6">
                                    <div
                                        className={
                                            'flex flex-col items-start gap-4'
                                        }
                                    >
                                        <ToggleButtonGroup
                                            options={[
                                                'Quick search',
                                                'Advanced search',
                                            ]}
                                            onToggle={handleSearchToggle}
                                        />
                                        {advancedSearch && (
                                            <ToggleButtonGroup
                                                options={[
                                                    'Custom search query',
                                                    'PICO/PECO framework',
                                                ]}
                                                onToggle={handleQueryToggle}
                                                primaryColor={'yellow'}
                                            />
                                        )}
                                    </div>

                                    {(!advancedSearch ||
                                        (customSearchQuery &&
                                            advancedSearch)) && (
                                        <div
                                            className={
                                                'flex flex-row items-center justify-between w-full gap-4'
                                            }
                                        >
                                            <CustomInputField
                                                value={
                                                    advancedSearch &&
                                                    customSearchQuery
                                                        ? inputValueCustomSearchQuery
                                                        : inputValueQuickSearch
                                                }
                                                onChange={(e) =>
                                                    advancedSearch &&
                                                    customSearchQuery
                                                        ? setInputValueCustomSearchQuery(
                                                              e.target.value
                                                          )
                                                        : setInputValueQuickSearch(
                                                              e.target.value
                                                          )
                                                }
                                                placeholder={`${advancedSearch && customSearchQuery ? '(COVID-19 OR coronavirus OR SARS-CoV-2)' : 'I.E. : Telerehabilitation in patients with COPD and Covid.'}`}
                                                className={'w-full'}
                                            />
                                            <div>
                                                <IconButton
                                                    loading={
                                                        loadingPreviewSearch
                                                    }
                                                    onClick={handleSearch}
                                                    icon={
                                                        <IoSearch
                                                            className={`text-blue w-6 h-6`}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {advancedSearch && !customSearchQuery && (
                                        <div
                                            className={
                                                'grid grid-cols-2 items-center justify-between w-full gap-y-3 gap-x-5'
                                            }
                                        >
                                            <CustomInputField
                                                label={'Population'}
                                                value={population}
                                                onChange={(e) =>
                                                    setPopulation(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder={`I.E.: Covid, COPD`}
                                                className={'w-full'}
                                            />
                                            <CustomInputField
                                                label={'Outcome'}
                                                value={outcome}
                                                onChange={(e) =>
                                                    setOutcome(e.target.value)
                                                }
                                                placeholder={`I.E.: Exercise capacity`}
                                                className={'w-full'}
                                            />
                                            <CustomInputField
                                                label={'Intervention'}
                                                value={intervention}
                                                onChange={(e) =>
                                                    setIntervention(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder={`I.E.: Telerehabilitation`}
                                                className={'w-full'}
                                            />
                                            <CustomInputField
                                                label={'Others'}
                                                value={others}
                                                onChange={(e) =>
                                                    setOthers(e.target.value)
                                                }
                                                placeholder={`I.E.: Elderly people`}
                                                className={'w-full'}
                                            />
                                        </div>
                                    )}

                                    {advancedSearch && !customSearchQuery && (
                                        <div className={'self-center'}>
                                            <CustomButton
                                                text={'Generate query'}
                                                onClick={handleGenerateQuery}
                                                loading={
                                                    loadingGeneratePicoQueryCall
                                                }
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={
                                            'flex flex-col self-end items-end gap-1'
                                        }
                                    >
                                        <ArrowToggleButton
                                            value={showDateRange}
                                            toggle={() =>
                                                setShowDateRange(!showDateRange)
                                            }
                                            label={'Date range'}
                                        />
                                        {/*fromYear and toYear*/}
                                        {showDateRange && (
                                            <div
                                                className={
                                                    'flex flex-row self-end gap-4'
                                                }
                                            >
                                                <CustomInputField
                                                    label={'From year'}
                                                    value={fromYear}
                                                    onChange={(e) =>
                                                        handleYearChange(
                                                            'from',
                                                            e
                                                        )
                                                    }
                                                    placeholder={`I.E.: 2010`}
                                                    className={'max-w-[100px]'}
                                                    type={'number'}
                                                />
                                                <CustomInputField
                                                    label={'To year'}
                                                    value={toYear}
                                                    onChange={(e) => {
                                                        handleYearChange(
                                                            'to',
                                                            e
                                                        )
                                                    }}
                                                    placeholder={`I.E.: 2024`}
                                                    className={'max-w-[100px]'}
                                                    type={'number'}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className={'text-sm text-darkGray'}>
                                        <span className={'font-bold'}>
                                            Tip:
                                        </span>{' '}
                                        the query should be aligned with the{' '}
                                        <span className={'font-bold'}>
                                            PICO/PECO
                                        </span>{' '}
                                        framework.
                                    </div>
                                </div>
                            </Card>
                        )}
                        <FunctionButtonsColumn
                            projectId={projectId!}
                            onShowHistoryClick={() => {}}
                        />
                    </div>

                    {isOwner && (
                        <ArrowToggleButton
                            label={'Query preview'}
                            value={showQueryPreview}
                            toggle={() => {
                                //set y position to the bottom of the page
                                setTimeout(() => {
                                    window.scrollTo({
                                        top: document.documentElement
                                            .scrollHeight,
                                        behavior: 'smooth', // Optional for smooth scrolling
                                    })
                                }, 0) // Ensure layout recalculates if needed
                                setShowQueryPreview(!showQueryPreview)
                            }}
                            disabled={!numberOfArticles && !numberOfReviews}
                        />
                    )}

                    {showQueryPreview && (
                        <Card>
                            <div className="flex flex-col items-center gap-4">
                                <div
                                    className={
                                        'text-xl text-darkGray dark:text-veryLightGray'
                                    }
                                >
                                    We found{' '}
                                    <span className={'font-bold'}>
                                        {numberOfArticles}
                                    </span>{' '}
                                    articles and{' '}
                                    <span className={'font-bold'}>
                                        {numberOfReviews}
                                    </span>{' '}
                                    reviews!
                                </div>

                                <div
                                    className={
                                        'text-base text-blue font-medium self-start'
                                    }
                                >
                                    PubMed query used
                                </div>

                                <div
                                    className={
                                        'flex flex-row items-start justify-between w-full gap-4'
                                    }
                                >
                                    <CustomInputField
                                        value={inputValueQueryResult}
                                        onChange={(e) =>
                                            setInputValueQueryResult(
                                                e.target.value
                                            )
                                        }
                                        placeholder="I.E. : Telerehabilitation in patients with COPD and Covid."
                                        className={'w-full'}
                                        multiline={true}
                                    />
                                    <div>
                                        <IconButton
                                            loading={
                                                loadingPreviewSearchFromResultInput
                                            }
                                            onClick={handleRefresh}
                                            icon={
                                                <FiRefreshCcw
                                                    className={`text-blue w-6 h-6`}
                                                />
                                            }
                                        />
                                    </div>
                                </div>

                                <div
                                    className={
                                        'text-sm text-darkGray self-start'
                                    }
                                >
                                    <span className={'font-bold'}>Tip:</span>{' '}
                                    Feel free to update it based on your
                                    criteria.
                                </div>

                                <CustomButton
                                    text={'Extract results'}
                                    onClick={handleExtractResults}
                                    loading={loadingCreateSearchCall}
                                />
                            </div>
                        </Card>
                    )}
                </div>
            )}
            {/*<LiteratureSearchHistorySection*/}
            {/*    onSearchClick={onHistorySearchSelected}*/}
            {/*    projectId={projectId!}*/}
            {/*/>*/}
            <CommentSection projectId={projectId!} stage={StageEnum.Search} />
        </div>
    )
}
