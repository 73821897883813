import { useEffect, useState } from 'react'
import LocalStorageUtil from '../utils/LocalStorageUtil'

// IMPORTANT: This hook is used to toggle the dark mode on the website
// needs to be initialized in one of the components to work
// no need to use it in the component, just initialize it
const useDarkMode = () => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        return (
            LocalStorageUtil.isDarkMode() ||
            (!LocalStorageUtil.isDarkMode() &&
                window.matchMedia('(prefers-color-scheme: dark)').matches)
        )
    })

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }

        // Save the dark mode preference to localStorage
        LocalStorageUtil.setDarkMode(isDarkMode)
    }, [isDarkMode])

    const toggleDarkMode = () => setIsDarkMode((prev) => !prev)

    return { isDarkMode, toggleDarkMode }
}

export default useDarkMode
