import React, { useState } from 'react'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as UserIcon } from '../../assets/user-icon.svg'
import { ReactComponent as CommentIcon } from '../../assets/comment-icon.svg'
import { PiPowerFill } from 'react-icons/pi'
import { IoMdSettings } from 'react-icons/io'
import { UserProfileCard } from './UserProfileCard'
import LocalStorageUtil from '../../utils/LocalStorageUtil'
import Modal from '../atoms/Modal'
import { FeedbackForm } from '../../features/feedback-form/FeedbackForm'
import { useLocation, useNavigate } from 'react-router-dom'
import useDarkMode from '../../hooks/useDarkMode'

const Navbar = () => {
    const { toggleDarkMode } = useDarkMode() //DON'T REMOVE this initializes the dark mode hook
    const navigate = useNavigate()
    const location = useLocation()
    const [showUserProfile, setShowUserProfile] = useState<boolean>(false)
    const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false)

    const handleGoToHome = () => {
        if (!LocalStorageUtil.isUserLoggedIn()) navigate('/login')
        else navigate('/')
    }

    const onSettingsClick = () => {
        setShowUserProfile(true) // Shows user profile settings
    }

    const logout = () => {
        //remove token and refresh token from local storage
        LocalStorageUtil.clearAll()
        //redirect to login page
        navigate('/login')
    }

    const hideUserProfile = (): boolean => {
        return (
            !location.pathname.includes('login') &&
            !location.pathname.includes('auth')
        )
    }

    return (
        <nav className="mt-10 mr-5">
            <div className="bg-white dark:bg-blue shadow-blueCustomShadow py-4 px-6 rounded-r-md">
                <div className="container mx-auto flex justify-between items-center px-1 -m-2">
                    <div
                        className="flex items-center space-x-2 cursor-pointer group transition-transform transform hover:scale-105"
                        onClick={handleGoToHome}
                    >
                        <Logo className="text-blue dark:text-lightGray w-[150px] h-[40px]" />
                    </div>

                    <div className="flex flex-row items-center space-x-20">
                        <div className="flex flex-row items-center space-x-6">
                            {hideUserProfile() && (
                                <CommentIcon
                                    onClick={() => setShowFeedbackForm(true)}
                                    className="w-[30px] h-[30px] cursor-pointer text-blue dark:text-lightGray hover:text-yellow dark:hover:text-yellow transition-colors duration-100 ease-in"
                                />
                            )}

                            {/* User icon & dropdown container */}
                            {hideUserProfile() && (
                                <div className="relative flex flex-col items-center group">
                                    {/* User icon */}
                                    <UserIcon
                                        className={`w-[30px] h-[30px] cursor-pointer ${location.pathname.includes('create-profile') ? 'text-yellow animate-bounce' : 'text-blue dark:text-lightGray'} fill-current group-hover:text-yellow transition-colors duration-100 ease-in`}
                                    />

                                    {/* Dropdown */}
                                    <div className="absolute z-10 pt-11 left-[-100px] mt-2 hidden group-hover:block group-hover:visible transition-colors duration-100 ease-in">
                                        <div className="flex flex-col bg-white dark:bg-blue  rounded-md  shadow-yellowCustomShadow p-2 space-y-2">
                                            {/* Log-out container */}
                                            <div
                                                onClick={logout}
                                                className="flex items-center space-x-1 w-full hover:text-yellow dark:hover:text-yellow cursor-pointer text-blue dark:text-lightGray transition-colors duration-100 ease-in"
                                            >
                                                <PiPowerFill className=" w-[30px] h-[30px]" />
                                                <span className="font-bold">
                                                    Sign out
                                                </span>
                                            </div>

                                            {/* Settings container */}
                                            <div
                                                className="flex items-center space-x-2 w-full hover:text-yellow dark:hover:text-yellow cursor-pointer text-blue dark:text-lightGray transition-colors duration-100 ease-in"
                                                onClick={onSettingsClick}
                                            >
                                                <IoMdSettings className="w-[30px] h-[30px]" />
                                                <span className="font-bold">
                                                    Settings
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Show user profile if it's true */}
            {showUserProfile && (
                <UserProfileCard onClose={() => setShowUserProfile(false)} />
            )}
            <Modal
                isOpen={showFeedbackForm}
                onClose={() => setShowFeedbackForm(false)}
            >
                <FeedbackForm feedbackSent={() => setShowFeedbackForm(false)} />
            </Modal>
        </nav>
    )
}

export default Navbar
