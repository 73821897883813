import React from 'react'

interface TextButtonProps {
    onClick: () => void // Function to execute when the button is clicked, provided as a prop
    buttonText: string // Text to display on the button
    additionalText: string // Additional text displayed next to the button
    buttonColor?: string // Optional background color for the button
    textColor?: string // Optional text color for the button
    additionalTextColor?: string // Optional text color for the additional text
}

const TextButton: React.FC<TextButtonProps> = ({
    onClick, // onClick function provided by the parent component
    buttonText,
    additionalText,
    buttonColor = 'bg-red', // Default background color (red) if not provided
    textColor = 'text-white', // Default button text color (white)
    additionalTextColor = 'text-red', // Default color for additional text (red)
}) => {
    return (
        <div className="flex flex-row gap-2 font-bold text-red w-fit">
            <div
                className={`${buttonColor} ${textColor} rounded-md px-2 py-0.5 transition-transform transform hover:scale-110 hover:cursor-pointer`}
                onClick={onClick} // Executes the provided onClick function
            >
                {buttonText}
            </div>
            <div className={`${additionalTextColor}`}>{additionalText}</div>{' '}
            {/* Additional text color */}
        </div>
    )
}

export default TextButton
