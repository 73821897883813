import { LoadingSpinner } from '../atoms/LoadingSpinner'

export const TableViewButton = ({
    text,
    onClick,
    isDisabled = false,
    backgroundColorClass,
    textColor = 'blue',
    loading = false,
    icon,
}: {
    text: string
    onClick: () => void
    isDisabled?: boolean
    backgroundColorClass: string
    textColor?: string
    loading?: boolean
    icon?: React.ReactNode
}) => {
    return (
        <button
            disabled={isDisabled || loading}
            onClick={onClick}
            className={`flex items-center justify-center rounded-md shadow-blueCustomShadow transition-transform transform hover:scale-105 hover:shadow-md pr-1 ${backgroundColorClass} text-${textColor} font-bold text-lg ${
                isDisabled || loading ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
        >
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className={'flex flex-row gap-x-1 items-center'}>
                    <div className={'bg-yellow rounded-l py-0.5 px-0.5'}>
                        {icon}
                    </div>
                    <span>{text}</span>
                </div>
            )}
        </button>
    )
}
