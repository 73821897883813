import React from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import type { ClinicalTrial } from '../../api/openapi'
import Card from '../../components/atoms/Card'
import { ReactComponent as BackIcon } from '../../assets/back-icon.svg'
import { IconButton } from '../../components/buttons/IconButton'

export const ClinicalTrialsTable = ({
    trials,
    onClose,
}: {
    trials: ClinicalTrial[]
    onClose: () => void
}) => {
    const columns: GridColDef[] = [
        {
            field: 'nCTNumber',
            headerName: 'NCT',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 0.5,
            renderCell: (params) => (
                <span className="cursor-pointer text-veryDarkGray dark:text-veryLightGray">
                    {params.value}
                </span>
            ),
        },
        {
            field: 'studyTitle',
            headerName: 'Title',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 1.5,
            renderCell: (params) => (
                <span className="cursor-pointer text-veryDarkGray dark:text-veryLightGray">
                    {params.value}
                </span>
            ),
        },
        {
            field: 'studyStatus',
            headerName: 'Status',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 0.7,
            renderCell: (params) => (
                <span className="cursor-pointer text-veryDarkGray dark:text-veryLightGray">
                    {params.value}
                </span>
            ),
        },
        {
            field: 'briefSummary',
            headerName: 'Summary',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 2,
            renderCell: (params) => (
                <span className="cursor-pointer text-veryDarkGray dark:text-veryLightGray">
                    {params.value}
                </span>
            ),
        },
    ]

    return (
        <Card>
            <div className="flex flex-col w-full h-full text-veryDarkGray">
                <div className="flex mr-auto mb-4">
                    <IconButton onClick={onClose} icon={<BackIcon />} />
                </div>
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        hideFooter={true} // Removes the footer
                        rows={trials.map((trial) => ({
                            ...trial,
                            id: trial.nCTNumber, // MUI DataGrid requires a unique `id` field
                        }))}
                        columns={columns}
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            borderColor: 'transparent',
                        }}
                    />
                </div>
            </div>
        </Card>
    )
}
