import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { linkedInAuthorization } from '../../middleware/authService'
import LocalStorageUtil from '../../utils/LocalStorageUtil'

const LinkedInRedirect = () => {
    const location = useLocation()
    const navigate = useNavigate()

    // Parse the authorization code
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')
    const error = queryParams.get('error')

    if (code) {
        console.log('Authorization Code:', code)
        // Handle the authorization code (e.g., pass it to your server or exchange for token)
    }

    if (error) {
        console.error('LinkedIn Login Error:', error)
        navigate('/login')
    }

    linkedInAuthorization(code!).then(
        (data) => {
            //save the token in local storage
            LocalStorageUtil.setToken(data.jwtToken)
            LocalStorageUtil.setRefreshToken(data.refreshToken)
            const email = JSON.parse(
                atob(data.jwtToken.split('.')[1])
            ).user_email
            LocalStorageUtil.setEmail(email)
            if (data.hasProfile) navigate('/')
            else navigate('/create-profile')
        },
        (error) => {
            console.error('Login failed', error)
        }
    )

    return (
        <div className="flex items-center h-[100px] justify-center text-center text-veryDarkGray dark:text-veryLightGray">
            <span>Loading...</span>
        </div>
    )
}

export default LinkedInRedirect
