import { ProjectsViewCard } from '../components/molecules/ProjectsViewCard'
import { useEffect, useState } from 'react'
import { ArrowToggleButton } from '../components/atoms/ArrowToggleButton'
import { fetchProjects } from '../middleware/projectService'
import { useFetchOnDemand } from '../hooks/useFetchOnDemand'

export function Home() {
    const [showHistory, setShowHistory] = useState(false)

    // Fetch data using useFetchOnDemand hook
    const { fetchData, data, loading, error } = useFetchOnDemand(() =>
        fetchProjects()
    )

    useEffect(() => {
        fetchData()
    }, [])

    const onRefresh = () => {
        fetchData()
    }

    return (
        <div className="flex flex-col items-center justify-center h-full gap-4 px-2 md:px-4 xl:px-8">
            {loading && (
                <div className={'dark:text-veryLightGray'}>Loading...</div>
            )}
            {error && (
                <div className={'dark:text-veryLightGray'}>Error: {error}</div>
            )}

            {!loading && !error && data && (
                <div className="flex flex-col items-center justify-center h-full gap-4 px-2 md:px-4 xl:px-8">
                    <ProjectsViewCard
                        data={[
                            ...data.ownerProjects!.filter(
                                (project) => project.status === 'ACTIVE'
                            ),
                            ...data.collaboratorProjects!.filter(
                                (project) => project.status === 'ACTIVE'
                            ),
                        ]}
                        historyView={false}
                        refreshData={onRefresh}
                    />

                    <ArrowToggleButton
                        label={'Show History'}
                        value={showHistory}
                        toggle={() => setShowHistory(!showHistory)}
                    />

                    {showHistory && (
                        <div>
                            <ProjectsViewCard
                                data={[
                                    ...data.ownerProjects!.filter(
                                        (project) => project.status == 'CLOSED'
                                    ),
                                    ...data.collaboratorProjects!.filter(
                                        (project) => project.status == 'CLOSED'
                                    ),
                                ]}
                                historyView={true}
                                refreshData={onRefresh}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
